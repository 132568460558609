<template>
  <div class="col-md-12 col-lg-10 center">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol>
            <CAlert :color="alertColor" closeButton :show.sync="alertShow">
              {{ alertMessage }}
            </CAlert>
          </CCol>
        </CRow>
        <CRow class="align-items-center">
          <CCol>
            This is a Conversational AI POC for MAPP POWER Dashboard.
          </CCol>
          <CCol md="auto">
            <div class="text-right">
              <CButton color="primary" @click="addSession">
                New Session
              </CButton>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CDataTable :items="sessions" :fields="fields" tableFilter pagination hover striped sorter>
          <template #conversation_id="{ item }">
            <td>
              <CLink @click="sessionDetail(item.conversation_id)">
                {{ item.conversation_id }}
              </CLink>
            </td>
          </template>
          <template #conversation_name="{ item }">
            <td>
              {{ item.conversation_name }}
            </td>
          </template>
          <template #created_at="{ item }">
            <td>
              {{ formatDate(item.created_at) }}
            </td>
          </template>
          <template #actions="{ item }">
            <td>
              <CButton color="danger" @click="deleteSession(item.conversation_id)">
                Delete
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";
import { AUTH_LOGOUT } from "../store/actions/auth";

export default {
  name: "Dashboard",
  data() {
    return {
      alertColor: "success",
      alertShow: false,
      alertMessage: "",
      sessions: [],
      fields: [
        { key: "conversation_id", label: "Session ID" },
        { key: "conversation_name", label: "Session Name" },
        { key: "created_at", label: "Created At" },
        { key: "actions", label: "Actions" }, 
      ],
    };
  },
  mounted() {
    this.loadSessions()
  },
  methods: {
    loadSessions() {
      axios
        .get(config.VUE_APP_ENV_ASSISTANT_URL + "/sessions")
        .then((resp) => {
          console.log(resp.data);
          this.sessions = resp.data.sessions;
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
              this.$router.push("/login");
            });
          } else {
            this.showAlert("warning", "Failed to list sessions : " + err);
          }
        });
    },
    sessionDetail(id) {
      this.$router.push("/sessions/" + id);
    },
    addSession() {
      this.$router.push("/sessions/new");
    },
    deleteSession(id) {
      axios
        .delete(`${config.VUE_APP_ENV_ASSISTANT_URL}/sessions/${id}`)
        .then(() => {
          this.showAlert("success", "Session deleted successfully.");
          this.loadSessions();
        })
        .catch((err) => {
          this.showAlert("danger", "Failed to delete session: " + err);
        });
    },
    showAlert(color, messsage) {
      this.alertShow = true;
      this.alertColor = color;
      this.alertMessage = messsage;
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString('id-ID', options).replace(' pukul ', ' - ');
      return formattedDate;
    },
  },
};
</script>

<style scoped>
.center {
  margin: 0 auto;
}
</style>
