var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 col-lg-10 center"},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',[_c('CAlert',{attrs:{"color":_vm.alertColor,"closeButton":"","show":_vm.alertShow},on:{"update:show":function($event){_vm.alertShow=$event}}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1)],1),_c('CRow',{staticClass:"align-items-center"},[_c('CCol',[_vm._v(" This is a Conversational AI POC for MAPP POWER Dashboard. ")]),_c('CCol',{attrs:{"md":"auto"}},[_c('div',{staticClass:"text-right"},[_c('CButton',{attrs:{"color":"primary"},on:{"click":_vm.addSession}},[_vm._v(" New Session ")])],1)])],1)],1),_c('CCardBody',[_c('CDataTable',{attrs:{"items":_vm.sessions,"fields":_vm.fields,"tableFilter":"","pagination":"","hover":"","striped":"","sorter":""},scopedSlots:_vm._u([{key:"conversation_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CLink',{on:{"click":function($event){return _vm.sessionDetail(item.conversation_id)}}},[_vm._v(" "+_vm._s(item.conversation_id)+" ")])],1)]}},{key:"conversation_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.conversation_name)+" ")])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.deleteSession(item.conversation_id)}}},[_vm._v(" Delete ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }